import './RootPage.css';
import {Layout, Menu, MenuProps} from 'antd';
import React, {lazy, Suspense, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import headerIcon from '../resource/wanzhou-logo-en-long.png';
import smallHeaderIcon from '../resource/wanzhou-icon-small.png';
import {AiOutlineMenu, AiOutlineTranslation} from 'react-icons/ai';
import CustomFooter from '../component/CustomFooter';

const HomePage = lazy(() => import('./HomePage'));
const IntroPage = lazy(() => import('./overview/IntroPage'));
const ProductionProcessPage = lazy(() => import('./productAndService/ProductionProcessPage'));
const CustomizationPage = lazy(() => import('./productAndService/CustomizationPage'));
const NotFoundPage = lazy(() => import('./NotFoundPage'));
const HonorPage = lazy(() => import('./overview/HonorPage'));
const FabricPage = lazy(() => import('./productAndService/FabricPage'));
const CulturePage = lazy(() => import('./overview/CulturePage'));
const AdvancedEquipmentPage = lazy(() => import('./competency/AdvancedEquipmentPage'));
const CustomerPage = lazy(() => import('./market/CustomerPage'));
const InternationalMarketPage = lazy(() => import('./market/InternationalMarketPage'));
const ChinaMarket = lazy(() => import('./market/ChinaMarket'));
const NewsPage = lazy(() => import('./news/NewsPage'));

const {Header, Content, Footer} = Layout;

type MenuItem = Required<MenuProps>['items'][number];

export default function RootPage() {

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  document.title = t('rootPage.browserTab');
  const {pathname} = useLocation();
  const [menuKeySelected, setMenuKeySelected] = useState('/');

  const menuItems: MenuItem[] = [
    {
      key: '/',
      label: t('rootPage.nav.home.label'),
    },
    {
      key: '/overview',
      label: t('rootPage.nav.overview.label'),
      children: [
        {
          key: '/overview/who-we-are',
          label: t('rootPage.nav.overview.sub1')
        },
        {
          key: '/overview/culture',
          label: t('rootPage.nav.overview.sub2')
        },
        {
          key: '/overview/honors',
          label: t('rootPage.nav.overview.sub3')
        }
      ]
    },
    {
      key: '/product-and-service',
      label: t('rootPage.nav.service.label'),
      children: [
        {
          key: '/product-and-service/fabric',
          label: t('rootPage.nav.service.sub1')
        },
        {
          key: '/product-and-service/production',
          label: t('rootPage.nav.service.sub2')
        },
        {
          key: '/product-and-service/customization',
          label: t('rootPage.nav.service.sub3')
        }
      ]
    },
    {
      key: '/competency',
      label: t('rootPage.nav.competency.label'),
      children: [
        // {
        //   key: '/competency/research-development',
        //   label: t('rootPage.nav.competency.sub1')
        // },
        // {
        //   key: '/competency/intelligent-production',
        //   label: t('rootPage.nav.competency.sub2')
        // },
        {
          key: '/competency/advanced-equipment',
          label: t('rootPage.nav.competency.sub3')
        },
        // {
        //   key: '/competency/industrial-chain',
        //   label: t('rootPage.nav.competency.sub4')
        // }
      ]
    },
    {
      key: '/market',
      label: t('rootPage.nav.market.label'),
      children: [
        {
          key: '/market/china',
          label: t('rootPage.nav.market.sub1')
        },
        {
          key: '/market/international',
          label: t('rootPage.nav.market.sub2')
        },
        {
          key: '/market/customer-cases',
          label: t('rootPage.nav.market.sub3')
        }
      ]
    },
    // {
    //   key: '/achievement',
    //   label: t('rootPage.nav.achievement.label'),
    //   children: [
    //     {
    //       key: '/achievement/production-line',
    //       label: t('rootPage.nav.achievement.sub1')
    //     },
    //     {
    //       key: '/achievement/big-data',
    //       label: t('rootPage.nav.achievement.sub2')
    //     }
    //   ]
    // },
    // {
    //   key: '/sustainability',
    //   label: t('rootPage.nav.sustainability.label'),
    //   children: [
    //     {
    //       key: '/sustainability/photovoltaic-power',
    //       label: t('rootPage.nav.sustainability.sub1')
    //     },
    //     {
    //       key: '/sustainability/energy-saving',
    //       label: t('rootPage.nav.sustainability.sub2')
    //     }
    //   ]
    // },
    {
      key: '/news',
      label: t('rootPage.nav.news.label'),
      // children: [
      //   {
      //     key: '/news/company',
      //     label: t('rootPage.nav.news.sub1')
      //   },
      //   {
      //     key: '/news/industry',
      //     label: t('rootPage.nav.news.sub2')
      //   },
      //   {
      //     key: '/news/media',
      //     label: t('rootPage.nav.news.sub3')
      //   }
      // ]
    },
    // {
    //   key: '/career',
    //   label: t('rootPage.nav.career.label'),
    //   children: [
    //     {
    //       key: '/career/recruitment',
    //       label: t('rootPage.nav.career.sub1')
    //     },
    //     {
    //       key: '/career/development',
    //       label: t('rootPage.nav.career.sub2')
    //     },
    //     {
    //       key: '/career/benefits',
    //       label: t('rootPage.nav.career.sub3')
    //     }
    //   ]
    // },
    {
      key: '/contact',
      label: t('rootPage.nav.contact.label')
    }
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '/contact') {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    } else {
      navigate(e.key);
    }
  };

  const handleSwitchLanguage = () => {
    const lang = localStorage.getItem('language');
    if (lang === 'zh') {
      localStorage.setItem('language', 'en');
      i18n.changeLanguage('en').then();
    } else if (lang === 'en') {
      localStorage.setItem('language', 'zh');
      i18n.changeLanguage('zh').then();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setMenuKeySelected(pathname);
  }, [pathname]);

  return (
    <div className="root-page">
      <Layout>
        <Header className="root-page-header">
          <img
            src={smallHeaderIcon}
            alt="Wan Zhou Holding Group Logo"
            className="root-page-header-logo-small"
            onClick={() => {
              navigate('/');
            }}
          />
          <div className="root-page-header-logo-container">
            <img
              src={headerIcon}
              alt="Wan Zhou Holding Group Logo"
              className="root-page-header-logo"
              onClick={() => {
                navigate('/');
              }}
            />
          </div>
          <Menu
            className="root-page-header-menu"
            mode="horizontal"
            items={menuItems}
            selectedKeys={[menuKeySelected]}
            overflowedIndicator={
              <div className="root-page-header-menu-overflow-icon"><AiOutlineMenu size={32}/></div>
            }
            onClick={handleMenuClick}
          />
          <div className="root-page-header-language-btn" onClick={handleSwitchLanguage}>
            <AiOutlineTranslation size={32}/>
          </div>
        </Header>
        <Content className="root-page-content">
          <Suspense fallback={<div>Loading Page...</div>}>
            <Routes>
              <Route path={'/'} element={<HomePage/>}></Route>
              <Route path={'/overview/who-we-are'} element={<IntroPage/>}></Route>
              <Route path={'/overview/culture'} element={<CulturePage/>}></Route>
              <Route path={'/overview/honors'} element={<HonorPage/>}></Route>
              <Route path={'/product-and-service/fabric'} element={<FabricPage/>}></Route>
              <Route path={'/product-and-service/production'} element={<ProductionProcessPage/>}></Route>
              <Route path={'/product-and-service/customization'} element={<CustomizationPage/>}></Route>
              <Route path={'/competency/advanced-equipment'} element={<AdvancedEquipmentPage/>}></Route>
              <Route path={'/market/customer-cases'} element={<CustomerPage/>}></Route>
              <Route path={'/market/china'} element={<ChinaMarket/>}></Route>
              <Route path={'/market/international'} element={<InternationalMarketPage/>}></Route>
              <Route path={'/news'} element={<NewsPage/>}></Route>
              <Route path={'*'} element={<NotFoundPage/>}></Route>
            </Routes>
          </Suspense>
        </Content>
        <CustomFooter/>
        <Footer style={{textAlign: 'center', background: '#15225c', color: '#ffffff'}}>
          ©{new Date().getFullYear()} {t('rootPage.footer.copyright')}
        </Footer>
      </Layout>
    </div>
  );

}