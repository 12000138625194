import './CustomFooter.css';
import youtubeQR from '../resource/youtube-qr.png';
import instagramQR from '../resource/instagram-qr.png';
import {useTranslation} from 'react-i18next';

export default function CustomFooter() {

  const {t} = useTranslation();

  return (
    <div className="custom-footer">
      <div className="custom-footer-info-container">
        <div className="custom-footer-info-cell">
          <h2 style={{paddingLeft: 4}}>{t('customFooter.follow')}</h2>
          <img src={instagramQR} alt="InstagramQR" style={{width: 128}}/>
          <img src={youtubeQR} alt="YoutubeQR" style={{width: 128}}/>
        </div>
        <div className="custom-footer-info-cell">
          <h2>{t('customFooter.contact')}</h2>
          <div className="custom-footer-contact-container">
            <div>
              <p style={{fontWeight: 'bold'}}>{t('customFooter.address-1-name')}</p>
              <p>{t('customFooter.address')}: {t('customFooter.address-1-address')}</p>
              <p>{t('customFooter.tel')}: +86 13567596666</p>
              <p>{t('customFooter.fax')}: +86 0575-856 067 93</p>
              <p>{t('customFooter.email')}: sales@wanzhou-zj.com</p>
            </div>
            <div>
              <p style={{fontWeight: 'bold'}}>{t('customFooter.address-2-name')}</p>
              <p>{t('customFooter.address')}: {t('customFooter.address-2-address')}</p>
              <p>{t('customFooter.tel')}: +86 0579-883 893 36</p>
              <p>{t('customFooter.fax')}: +86 0579-882 323 88</p>
              <p>{t('customFooter.email')}: sales@wanzhou-zj.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}